import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ProjektCarousel from './ProjektCarousel';
import Header from './Header';
import Footer from './Footer';
import Impressum from './Impressum'; // Impressum importieren
import Datenschutz from './Datenschutz'; // Datenschutz importieren
import CookieBanner from './CookieBanner'; // Cookie-Banner importieren

function AppContent() {
  const [showCarousel, setShowCarousel] = useState(false);
  const location = useLocation(); // Verwende den useLocation-Hook

  const handleVideoEnd = () => {
    setShowCarousel(true); // Wechselt zu Carousel, wenn das Video endet
  };

  const isOnLegalPage = location.pathname === '/Impressum' || location.pathname === '/Datenschutz';

  return (
    <div className="min-h-screen bg-black flex flex-col">
      {showCarousel && !isOnLegalPage && <Header />} {/* Header wird oben angezeigt */}

      <div className="flex-grow flex items-center justify-center">
        {!showCarousel ? (
          <div className="w-full h-auto sm:h-auto lg:h-auto">
            <video
              src="/introwebsite.mp4" // Hier dein Video im public-Ordner referenzieren
              className="w-full h-auto"
              autoPlay
              muted
              playsInline // Inline-Wiedergabe auf iPhone erlauben
              preload="auto" // Video wird vorgeladen
              onEnded={handleVideoEnd}
            />
          </div>
        ) : (
          !isOnLegalPage && <ProjektCarousel /> // Zeigt das Karussell an, wenn das Video endet und wir nicht auf Impressum/Datenschutz sind
        )}
      </div>

      <Footer />
      <CookieBanner />

      {/* Routes definieren, die nur angezeigt werden, wenn die entsprechenden Seiten aufgerufen werden */}
      <Routes>
        <Route path="/Impressum" element={<Impressum />} />
        <Route path="/Datenschutz" element={<Datenschutz />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
