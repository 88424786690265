import React, { useState, useEffect } from 'react';
import ProjektCard from './ProjektCard';

const projekts = [
  {
    name: 'SkyFrame',
    job: 'Experimentirung mit Videos',
    image: '/skyframe.png',
    website: 'https://tobias.gager.work',
  },
  {
    name: 'Pixeo Gager',
    job: 'Portfolio of Video & Foto',
    image: '/pixeo.png',
    website: 'https://pixeo.gager.work',
  },
  {
    name: 'Gager Goalkeeping',
    job: 'Social Media Sportkanal',
    image: '/goalkeeping.jpg',
    website: 'https://www.instagram.com/gager.goalkeeping/',
  },
  {
    name: 'OWN THINGS',
    job: 'Development von Tagesabläufen',
    image: '/ownthings.png',
    website: 'https://ownthings.at',
  },
  {
    name: 'DevSoft Gager',
    job: 'Software Projekte',
    image: '/develop.png',
    website: 'https://github.com/gager_dev',
  }
];

const ProjektCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevProjekt = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? projekts.length - 1 : prevIndex - 1
    );
  };

  const nextProjekt = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === projekts.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Add automatic transition every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextProjekt, 5000); // Change project every 5 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const getCardStyle = (index) => {
    const position = (index - currentIndex + projekts.length) % projekts.length;

    if (position === 0) {
      return {
        transform: 'translateX(0) scale(1)',
        zIndex: 50,
        opacity: 1,
      };
    } else if (position === 1) {
      return {
        transform: 'translateX(200px) scale(0.8)',
        zIndex: 40,
        opacity: 0.8,
      };
    } else if (position === 4) {
      return {
        transform: 'translateX(-200px) scale(0.8)',
        zIndex: 40,
        opacity: 0.8,
      };
    } else {
      return {
        transform: 'translateX(0) scale(0.5)',
        zIndex: 30,
        opacity: 0,
      };
    }
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-10 flex items-center justify-center">
      {projekts.map((projekts, index) => (
        <div
          key={index}
          className="absolute transition-transform duration-500"
          style={getCardStyle(index)}
        >
          <ProjektCard projekts={projekts} />
        </div>
      ))}

      {/* Left Arrow */}
      <div className="absolute top-1/2 transform -translate-y-1/2 left-4 z-50">
        <button
          className="text-white text-4xl p-3 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 focus:outline-none transition-colors"
          onClick={prevProjekt}
        >
          &#8592;
        </button>
      </div>

      {/* Right Arrow */}
      <div className="absolute top-1/2 transform -translate-y-1/2 right-4 z-50">
        <button
          className="text-white text-4xl p-3 rounded-full bg-black bg-opacity-50 hover:bg-opacity-75 focus:outline-none transition-colors"
          onClick={nextProjekt}
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default ProjektCarousel;
